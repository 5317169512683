import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "advanced-settings"
    }}>{`Advanced Settings`}</h1>
    <p>{`Use this page to customise key aspects of the events calendar.`}</p>
    <Alert kind="warning" mdxType="Alert">This functionality is exclusively available to users with Admin level access permissions.</Alert>
    <h2 {...{
      "id": "advanced-settings-1"
    }}>{`Advanced Settings`}</h2>
    <ol>
      <li parentName="ol">{`Select the 'Advanced' tab on the left-hand menu`}</li>
      <li parentName="ol">{`Choose a 'Single Name' and 'Plural Name' for the title of your locations`}</li>
      <li parentName="ol">{`Set how long before an event 'Email reminders' are sent in hours`}</li>
      <li parentName="ol">{`Set the number of hours for the 'Waiting List Window' - this is how long a person has to claim their ticket before it expires`}</li>
      <li parentName="ol">{`Set the 'Max Waiting List Tickets' number that can be requested by a member for a single event`}</li>
      <li parentName="ol">{`Set the number of hours before an event 'Refund Deadline'- this is how long before an event a booked in member can cancel a booking and still receive a refund when cancelling online`}</li>
      <li parentName="ol">{`Set the number of days after an event that an email for 'Event Feedback (is) Sent'`}</li>
      <li parentName="ol">{`Select the colour swatch or type a hex code to choose a colour that is used as the 'Calendar Theme Colour'- for best practice use a dark colour`}</li>
      <li parentName="ol">{`Enter an 'Accounts Email'- where key information regarding payment information will be sent`}</li>
      <li parentName="ol">{`Enter email address/es for 'Event approval notifications' to receive notifications for each event that is approved - you can input multiple email addresses with a comma between emails addresses`}</li>
      <li parentName="ol">{`Set a warning message with 'Warning Button Text' 'Warning Button URL' and 'Warning Message' and leave blank if not required- if filled this can provide members with key booking or event information and is displayed on the top of the public home view`}</li>
      <li parentName="ol">{`Provide and set API keys - do not tamper with these fields once you're set up`}</li>
    </ol>
    <h3 {...{
      "id": "watch-the-video"
    }}>{`Watch The Video`}</h3>
    <YouTube mdxType="YouTube"><iframe src="//www.youtube.com/embed/EJcwR3gSuIE" frameBorder="0" allowFullScreen style={{
        "position": "absolute",
        "top": "0",
        "left": "0",
        "width": "100%",
        "height": "100%"
      }}></iframe></YouTube>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      